import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SignInForm from '../components/SignIn';

const SignInPage = () => (
  <Fragment>
    <SignInForm />
  </Fragment>
);

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
);
